<ion-item class="border-light-2 margin-top-s" lines="none">
  <ion-select
    class="full-width font-default padding-left-none"
    [ngModel]="selectedGrade"
    (ionChange)="valueSelected($event.detail.value)"
    [selectedText]="!selectedGrade ? 'Select' : ''"
    [interfaceOptions]="{ header: dropdownTitle }"
    label-placement="floating"
    mode="md"
  >
    <ion-select-option value="{{ grade.name }}" *ngFor="let grade of gradeList">{{ grade.name }}</ion-select-option>
  </ion-select>
</ion-item>
