<div tabindex="0" role="dialog" aria-label="#title">
  <ion-header>
    <ion-toolbar color="light">
      <ion-title>
        <h1 class="font-default margin-0" id="title">Add New Grade</h1>
      </ion-title>
      <ion-buttons slot="primary">
        <ion-button color="dark" (click)="close()" id="close-button" icon-only>
          <ion-icon name="close" id="close-icon"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="margin-top-m margin-left-m margin-right-m">
    <div class="padding-l">
      <div [class.error]="!valid && !validGradeName()" id="enter-grade">
        Enter a grade
        <ion-item lines="none" class="border-light-2 margin-top-l margin-bottom-l">
          <ion-input
            id="grade-input"
            autocapitalize="sentences"
            placeholder="A-"
            [(ngModel)]="grade.name"
            maxlength="20"
            mode="md"
            uppercase
          ></ion-input>
        </ion-item>
      </div>
      <div *ngIf="!valid && !validGradeName()" class="color-error margin-top-xs margin-bottom-l d-flex">
        <span class="d-flex align-center padding-right-xs font-l">
          <ion-icon aria-hidden="true" name="alert-circle-sharp" id="grade-error-icon"></ion-icon>
        </span>
        <span *ngIf="alreadyExists; else defaultError" id="grade-error-message"
          >This grade already exists. Please enter a different grade.</span
        >
        <ng-template #defaultError><span id="value-req">Grade value is required.</span></ng-template>
      </div>

      <div id="no-of-times-received">
        Enter the no. of times you received this grade.
        <app-grade-times-received
          [grade]="grade"
          [valid]="valid"
          (selectedTimesAchieved)="updateTimesAchieved($event)"
        ></app-grade-times-received>
      </div>

      <div id="percentage-range-grade">
        Enter the % range for this grade.
        <app-grade-range [grade]="grade" [gradeList]="gradeList" [valid]="valid" [newGrade]="true"></app-grade-range>
      </div>
    </div>
  </div>

  <div class="display-flex flex-reverse">
    <ion-button fill="clear" color="primary" (click)="addGrade()" id="add-grade-button"> Add Grade </ion-button>
    <ion-button fill="clear" color="primary" (click)="close()" id="close-button"> Close </ion-button>
  </div>
</div>
