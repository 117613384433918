<ion-content>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>

    <ion-buttons slot="primary">
      <ion-button icon-only (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="background-light position-sticky z-index-1 flex-box padding-right-s border-top-light-2 border-bottom-light-2" id="search-bar">
    <ion-icon class="padding-s" name="search" size="small"></ion-icon>
    <ion-input
      #autocompletesearchbar
      (ionChange)="clearInput()"
      (keydown)="handleKeys($event)"
      (ionBlur)="cursorIdx = null"
      placeholder="Start typing"
      id="autocomplete-searchbar"
      type="text"
      [maxlength]="maxChars"
      [formControl]="searchControl"
      mode="md"
      [clearInput]="true"
    ></ion-input>
    <span *ngIf="otherCode">{{searchString.length}}/{{maxChars}}&nbsp;</span>
  </div>
  <div>
    <ion-list>
      <ion-list-header class="margin-bottom-0 justify-content-end" lines="none">
        <ion-label class="margin-right-l font-default not-bold" *ngIf="searchString.length > 2">{{resultList.length}} results</ion-label>
        <ion-label class="margin-right-l font-s not-bold" *ngIf="searchString.length < 3"
          >Please enter more than 2 character for search.</ion-label
        >
      </ion-list-header>
      <app-loading-indicator *ngIf="loading | async"></app-loading-indicator>

      <ng-template [ngIf]="!!shownResultsList.length">
        <ion-button
          fill="clear"
          expand="full"
          class="font-default color-dark-1 cursor-pointer full-width margin-0"
          *ngFor="let result of shownResultsList; let i = index"
          (click)="select(result)"
          [class.selected]="i == cursorIdx"
          mode="md"
        >
          <span class="padding-s"> {{ getSearchField(result) }}</span>
        </ion-button>
      </ng-template>
      <ion-button
        fill="clear"
        expand="full"
        *ngIf="otherCode && searchString.length > 0 && !(loading | async)"
        (click)="useOther()"
        class="full-width font-default color-dark-1 item item-block item-md cursor-pointer margin-0"
      >
        <span class="padding-s">Other (use what you typed)</span>
      </ion-button>

      <ion-item *ngIf="page > 0 || hasNextPage()" lines="none">
        <div class="flex-box full-screen color-placeholder padding-right-l padding-left-l">
          <span *ngIf="page > 0" (click)="gotoPrevPage()" (keyup.enter)="gotoPrevPage()" class="cursor-pointer flex-box" tabindex="0"
            ><span class="flex-box flex-end"><ion-icon name="chevron-back-sharp"></ion-icon></span> previous</span
          >
          <span>&nbsp;</span>
          <span *ngIf="hasNextPage()" (click)="gotoNextPage()" (keyup.enter)="gotoNextPage()" class="cursor-pointer flex-box" tabindex="0"
            >next <span class="flex-box flex-end"><ion-icon name="chevron-forward-sharp"></ion-icon> </span>
          </span>
        </div>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
