<div class="background-light-1">
  <div class="inner-content inner-content-padding-bottom padding-m">
    <h1 class="padding-top-5xl margin-top-0 color-primary not-bold font-xl text-center" tabindex="0" #title id="title">
      Your estimated GPE:
    </h1>

    <div class="padding-left-right-7 padding-top-5xl">
      <div class="tooltip gpafield-margin-left" [style.--gpa-margin-left]="gpa.finalGpa">
        <div class="top">
          <ion-item class="font-l" lines="none">
            <ion-label class="text-center color-primary" id="final-gpa">{{ gpa.finalGpa }}</ion-label>
          </ion-item>
          <i></i>
        </div>
      </div>

      <div class="range-bg"><div class="margin-left-1" id="grade-range-scale"></div></div>
      <div class="d-flex flex-space-between" id="grade-range-scale-numbers">
        <span>0</span>
        <span>9</span>
      </div>
      <span class="d-flex flex-center font-s italic" id="grade-range-scale-label">UoA grading scale</span>
    </div>

    <span class="d-flex flex-end margin-bottom-m margin-top-m" id="copy-span">
      <ion-button
        fill="clear"
        slot="icon-only"
        aria-label="copy final gpa value"
        (click)="copyGpa()"
        (keydown.enter)="copyGpa()"
        id="copy-button"
      >
        <ion-icon src="assets/ic_copy.svg" class="copy-icon"></ion-icon
      ></ion-button>
    </span>
    <div>
      <ion-button
        (click)="changeToggle()"
        id="toggle-button"
        class="d-flex flex-center view-summary-text"
        fill="clear"
        size="small"
        aria-live="assertive"
      >
        <div class="d-flex button-content">
          <span class="padding-top-m">View Summary</span>
          <span class="d-flex flex-center padding-bottom-m" id="icons">
            <ion-icon *ngIf="!toggle" name="chevron-down-outline" id="chevron-down" aria-label="section closed"></ion-icon>
            <ion-icon *ngIf="toggle" name="chevron-up-outline" id="chevron-up" aria-label="section open"></ion-icon>
          </span>
        </div>
      </ion-button>

      <div *ngIf="toggle && (gpaType == gradeTypes.NUMERIC || gpaType == gradeTypes.PERCENT)">
        <ion-grid>
          <ion-row id="num-exgpa" *ngIf="gpaType == gradeTypes.NUMERIC">
            <ion-col>Overall GPA:</ion-col><ion-col>{{ gpa.exgpa }}</ion-col>
          </ion-row>
          <ion-row id="percent-exgpa" *ngIf="gpaType == gradeTypes.PERCENT">
            <ion-col>Overall grade:</ion-col><ion-col>{{ gpa.exgpa }}%</ion-col>
          </ion-row>
          <ion-row id="tertiary-inst"
            ><ion-col>Tertiary Institution:</ion-col><ion-col>{{ tertiaryInstitutionName }}</ion-col></ion-row
          >
          <ion-row *ngIf="gpaType == gradeTypes.NUMERIC" id="max-gpa"
            ><ion-col>Maximum GPA:</ion-col><ion-col>{{ gpa.maxPass }}</ion-col>
          </ion-row>
          <ion-row *ngIf="gpaType == gradeTypes.PERCENT" id="max-grade"
            ><ion-col>Maximum grade:</ion-col><ion-col>{{ gpa.maxPass }}%</ion-col>
          </ion-row>
          <ion-row *ngIf="gpaType == gradeTypes.NUMERIC" id="min-pass"
            ><ion-col>Pass GPA:</ion-col><ion-col>{{ gpa.minPass }}</ion-col>
          </ion-row>
          <ion-row *ngIf="gpaType == gradeTypes.PERCENT" id="pass-grade"
            ><ion-col>Pass grade:</ion-col><ion-col>{{ gpa.minPass }}%</ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid *ngIf="gpa.minB || gpa.minA">
          <ion-row id="optional-label">
            <ion-col><i>(Optional)</i></ion-col>
          </ion-row>
          <ion-row *ngIf="gpa.minB" id="gpa-minB">
            <ion-col>Lowest B grade:</ion-col>
            <ion-col>{{ gpa.minB }}<span *ngIf="gpaType == gradeTypes.PERCENT">%</span></ion-col>
          </ion-row>
          <ion-row *ngIf="gpa.minA" id="gpa-minA">
            <ion-col>Lowest A grade:</ion-col>
            <ion-col>{{ gpa.minA }}<span *ngIf="gpaType == gradeTypes.PERCENT">%</span></ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div
        *ngIf="
          (gpaType == gradeTypes.ABC ||
            gpaType == gradeTypes.HONOURS ||
            gpaType == gradeTypes.DISTINCTION ||
            gpaType == gradeTypes.EXCELLENCE ||
            gpaType == gradeTypes.OTHER) &&
          this.toggle
        "
      >
        <ion-grid>
          <ion-row id="grade-scale">
            <ion-col>Grade scale:</ion-col>
            <ion-col>
              <span *ngFor="let grade of finalGradesList; let i = index">
                {{ grade.name }} ({{ grade.rangeFrom }}-{{ grade.rangeTo }})<span *ngIf="i != finalGradesList.length - 1">,</span>
              </span>
            </ion-col>
          </ion-row>
          <ion-row id="grades-achieved">
            <ion-col>Grades achieved:</ion-col>
            <ion-col
              ><span *ngFor="let grade of achievedGrades; let i = index"
                >{{ grade.timesAchieved }} {{ grade.name }}<span *ngIf="i < achievedGrades.length - 1">, </span>
              </span></ion-col
            >
          </ion-row>
          <ion-row id="tertiary-inst-non-numeric">
            <ion-col>Tertiary Institution:</ion-col>
            <ion-col>{{ tertiaryInstitutionName }}</ion-col>
          </ion-row>
          <ion-row id="pass-grade-non-numeric"
            ><ion-col>Pass grade:</ion-col> <ion-col>{{ minPass }}</ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid *ngIf="minB || minA">
          <ion-row id="optional-label-non-numeric"
            ><ion-col><i>(Optional)</i></ion-col>
          </ion-row>
          <ion-row id="minB" *ngIf="minB">
            <ion-col>Lowest B grade:</ion-col><ion-col>{{ minB }}</ion-col>
          </ion-row>
          <ion-row id="minA" *ngIf="minA">
            <ion-col>Lowest A grade:</ion-col><ion-col>{{ minA }}</ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</div>

<div class="inner-content">
  <h2 class="margin-top-4xl text-center color-primary not-bold" id="next-steps">Next Steps</h2>
  <div class="border-full"></div>

  <ion-card
    class="pointer"
    target="_blank"
    href="https://www.auckland.ac.nz/en/study/applications-and-admissions/apply-now.html"
    id="apply-now-card"
  >
    <ion-card-content>
      <!--Same id is used for google analytics-->
      <div class="flex-box color-primary">
        <h3 id="start-new-application-link-1" id="apply-now-label">Start your application now</h3>
        <span class="flex-box font-xl">
          <ion-icon id="start-new-application-link-2" name="chevron-forward-sharp"></ion-icon>
        </span>
      </div>
    </ion-card-content>
  </ion-card>
  <h3 class="d-flex margin-top-l margin-bottom-l not-bold color-primary flex-center" id="or-text">OR</h3>

  <ion-card
    class="pointer"
    target="_blank"
    href="https://www.auckland.ac.nz/en/study/study-options/find-a-study-option.html"
    id="study-option-card"
  >
    <ion-card-content>
      <!--Same id is used for google analytics-->
      <div class="flex-box color-primary" id="programme-requirements-link">
        <h3 id="programme-requirements-link-1">Find entry requirements for your chosen programme</h3>
        <span class="flex-box font-xl">
          <ion-icon id="programme-requirements-link-2" name="chevron-forward-sharp"></ion-icon>
        </span>
      </div>
    </ion-card-content>
  </ion-card>

  <div class="d-flex flex-center margin-top-4xl margin-bottom-4xl buttons">
    <ion-button (click)="edit()" shape="round" size="large" class="btn-min-width" id="edit-button">Edit</ion-button>
    <ion-button (click)="recalculate()" shape="round" size="large" class="btn-min-width" fill="outline" id="reset-button">Reset</ion-button>
  </div>
</div>
