import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { ErrorPagesModule, ErrorToastService, UoaErrorsConfig } from '@uoa/error-pages';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterModule, HeaderModule, LoadingIndicatorModule, GpaTypeModule, NumericSummaryModule } from './shared';
import { environment } from '../environments/environment';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NumericGpaPageModule } from './pages/numeric-gpa/numeric-gpa.module';
import { NonNumericPageModule } from './pages/non-numeric/non-numeric.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AppErrorsConfig } from './core/services/app-errors-config.service';
import { AppToastService } from './core/services/app-toast.service';
import { FormsModule } from '@angular/forms';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        ErrorPagesModule,
        IonicStorageModule.forRoot(),
        HeaderModule,
        FooterModule,
        GpaTypeModule,
        LoadingIndicatorModule,
        NumericGpaPageModule,
        NonNumericPageModule,
        NumericSummaryModule,
        GoogleTagManagerModule.forRoot({
            id: environment.googleTagId,
            // gtm_auth: YOUR_GTM_AUTH,
            // gtm_preview: YOUR_GTM_ENV
        }),
        FormsModule], providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: UoaErrorsConfig, useClass: AppErrorsConfig },
        { provide: ErrorToastService, useClass: AppToastService },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
