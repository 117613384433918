import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionsListComponent } from './institutions-list.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InstitutionAutoCompleteModal } from './institution-autocomplete.modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LoadingIndicatorModule } from '..';

const COMPONENTS = [InstitutionsListComponent, InstitutionAutoCompleteModal];

@NgModule({ declarations: COMPONENTS,
    exports: COMPONENTS,
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, LoadingIndicatorModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class InstitutionsListModule {}
